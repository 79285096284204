import React from 'react';
import content1 from './images/content1.jpg';
import feature2 from './images/feature2.jpg';
import bluecross from './images/bluecross.png';
import aetna from './images/aetna.png';
import uhc from './images/uhc.png';
import pricing from './images/pricing.jpg';

export const Banner00DataSource = {
  wrapper: { className: 'banner0 kjd3a69w8e-editor_css' },
  textWrapper: { className: 'banner0-text-wrapper' },
  title: {
    className: 'banner0-title kjd3b9mz3ul-editor_css',
    children: (
      <span>
        <p>
          Tangerine Health<br />
        </p>
      </span>
    ),
  },
  content: {
    className: 'banner0-content kjd9l2n4vr-editor_css',
    children: (
      <span>
        <p>
          Online Urgent Care<br />
        </p>
      </span>
    ),
  },
  button: {
    className: 'banner0-button kjd9ndw11or-editor_css',
    children: (
      <span>
        <p>Register</p>
      </span>
    ),
    href: 'https://www.tangerinehealth.co/app/register',
    type: 'primary',
  },
};
export const Content10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: { children: content1 },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title kjd9u5p13an-editor_css',
    children: (
      <span>
        <p>A modern, innovative care to meet today's challenges</p>
      </span>
    ),
  },
  content: {
    className: 'content1-content kjd9w81aeng-editor_css',
    children: (
      <span>
        <p>
          Take care your health with affordable, convenient, and quality care
        </p>
      </span>
    ),
  },
};
export const Feature20DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: { children: feature2 },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: {
    className: 'content2-title kjda5657ndn-editor_css',
    children: (
      <span>
        <p>
          Care when you need it the most<br />
        </p>
      </span>
    ),
  },
  content: {
    className: 'content2-content kjda6wbpmaf-editor_css',
    children: (
      <span>
        <span>
          <span>
            <span>
              <p>
                Just register an account with us. Schedule an appointment within
                the next 24 hours. Or see a doctor right away if available.
                Start a live video visit from the comfort of your home. All you
                need is a web browser on your phone or laptop.<br />
              </p>
            </span>
          </span>
        </span>
      </span>
    ),
  },
};
export const Content50DataSource = {
  wrapper: { className: 'home-page-wrapper content5-wrapper' },
  page: { className: 'home-page content5' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>We Accept Health Insurance</p>
          </span>
        ),
        className: 'title-h1 kjdb4kxtk0g-editor_css',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <br />
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'content5-img-wrapper',
    gutter: 16,
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: { children: bluecross },
          content: {
            children: (
              <span>
                <p>BlueCross BlueShield</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: { children: aetna },
          content: {
            children: (
              <span>
                <p>Aetna</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: { children: uhc },
          content: {
            children: (
              <span>
                <p>
                  United Health Care<br />
                </p>
              </span>
            ),
          },
        },
      },
      // {
      //   name: 'block3',
      //   className: 'block',
      //   md: 6,
      //   xs: 24,
      //   children: {
      //     wrapper: { className: 'content5-block-content' },
      //     img: {
      //       children:
      //         'https://zos.alipayobjects.com/rmsportal/faKjZtrmIbwJvVR.svg',
      //     },
      //     content: { children: 'Ant Motion' },
      //   },
      // },
      // {
      //   name: 'block4',
      //   className: 'block',
      //   md: 6,
      //   xs: 24,
      //   children: {
      //     wrapper: { className: 'content5-block-content' },
      //     img: {
      //       children:
      //         'https://t.alipayobjects.com/images/rmsweb/T11aVgXc4eXXXXXXXX.svg',
      //     },
      //     content: { children: 'Ant Design' },
      //   },
      // },
      // {
      //   name: 'block5',
      //   className: 'block',
      //   md: 6,
      //   xs: 24,
      //   children: {
      //     wrapper: { className: 'content5-block-content' },
      //     img: {
      //       children:
      //         'https://zos.alipayobjects.com/rmsportal/faKjZtrmIbwJvVR.svg',
      //     },
      //     content: { children: 'Ant Motion' },
      //   },
      // },
      // {
      //   name: 'block6',
      //   className: 'block',
      //   md: 6,
      //   xs: 24,
      //   children: {
      //     wrapper: { className: 'content5-block-content' },
      //     img: {
      //       children:
      //         'https://t.alipayobjects.com/images/rmsweb/T11aVgXc4eXXXXXXXX.svg',
      //     },
      //     content: { children: 'Ant Design' },
      //   },
      // },
      // {
      //   name: 'block7',
      //   className: 'block',
      //   md: 6,
      //   xs: 24,
      //   children: {
      //     wrapper: { className: 'content5-block-content' },
      //     img: {
      //       children:
      //         'https://zos.alipayobjects.com/rmsportal/faKjZtrmIbwJvVR.svg',
      //     },
      //     content: { children: 'Ant Motion' },
      //   },
      // },
    ],
  },
};
export const Pricing00DataSource = {
  wrapper: { className: 'home-page-wrapper pricing0-wrapper' },
  OverPack: { playScale: 0.3, className: 'home-page pricing0' },
  imgWrapper: { className: 'pricing0-img-wrapper', md: 12, xs: 24 },
  img: {
    className: 'pricing0-img kjdbcgk584o-editor_css',
    name: 'image',
    children: pricing,
  },
  childWrapper: {
    className: 'pricing0-text-wrapper',
    md: 12,
    xs: 24,
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <span>
                <p>
                  We can see you even with no insurance<br />
                </p>
              </span>
            </span>
          </span>
        ),
        className: 'pricing0-title kjdblg7fhad-editor_css',
      },
      {
        name: 'content',
        children: (
          <span>
            <br />
          </span>
        ),
        className: 'pricing0-content',
      },
      {
        name: 'pricing',
        children: (
          <span>
            <span>
              <p>
                $ 25 per visit<br />
              </p>
            </span>
          </span>
        ),
        className: 'pricing0-pricing',
      },
      {
        name: 'button',
        children: {
          href: 'https://www.tangerinehealth.co/app/register',
          // type: 'primary',
          children: (
            <span>
              <span>
                <div>Register</div>
              </span>
            </span>
          ),
          className: 'kjdbpltwhq7-editor_css',
        },
        className: 'kjdbqptqois-editor_css',
      },
    ],
  },
};
export const Banner20DataSource = {
  wrapper: { className: 'banner2' },
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        page: { className: 'home-page banner2-page kjdch9yfwth-editor_css' },
        textWrapper: { className: 'banner2-text-wrapper' },
        bg: { className: 'bg bg0 kjdchyedebf-editor_css' },
        title: {
          className: 'banner2-title kjdch67f1ir-editor_css',
          children: (
            <span>
              <p>
                Still have questions?<br />
              </p>
            </span>
          ),
        },
        content: {
          className: 'banner2-content kjdcjmaiwl-editor_css',
          children: (
            <span>
              <br />
            </span>
          ),
        },
        button: {
          className: 'banner2-button kjdclfdlyh9-editor_css',
          children: (
            <span>
              Learn More<br />
            </span>
          ),
          href: 'https://www.tangerinehealth.co/urgent-care/learn-more',
          type: 'primary',
        },
      },
    ],
  },
};
export const Footer00DataSource = {
  wrapper: { className: 'home-page-wrapper footer0-wrapper' },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        <span>
          <span>
            <span>
              ©2020{' '}
              <a href="https://www.tangerinehealth.co">Tangerine Health</a> All
              Rights Reserved
            </span>
          </span>
        </span>
      </span>
    ),
  },
};
